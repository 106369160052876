import React, { useCallback, useEffect, useState } from 'react'
import {
	CenteredPageSection,
	CenteredSection,
	PageSidePaddingWrapper,
} from '../../reusable_components/layout/PageComponents'
import HelmetHead from '../../layout/HelmetHead'
import { getRootDomainStartPageData, isRootDomain } from '../../../utils/applicationHelper'
import { theme } from '../../../utils/theme'
import styled from 'styled-components'
import ReusableSlider from '../../reusable_components/other/ReusableSlider'
import ConditionalLink from '../../reusable_components/other/ConditionalLink'
import BigSearchBar from './BigSearchBar'
import CourseCardsWrapper from '../../reusable_components/course_cards/CourseCardsWrapper'
import { Link } from 'react-router-dom'
import Button from '../../reusable_components/buttons/Button'
import SaFrontendComponent from '../../reusable_components/other/sa_frontend_components/SaFrontendComponent'
import { getCloudinaryImageUrl, getCloudinaryVideoUrl } from '../../../utils/imageHelper'
import Breakpoint from '../../reusable_components/responsive/Breakpoint'

// The FIXED_DIV where the big search bar is is in a parent with lower z-index than the rest of the page, so the search results are below the rest of the page
// Therefore we add this div outside of FIXED_DIV and the use reactPortal to render the search results to it
const SearchResults = styled.div`
	position: absolute;
	.react-autosuggest__suggestions-container {
		opacity: 0;
		height: 0;
		transition:
			opacity 0.3s 0.2s,
			height 0.3s;
	}
	.react-autosuggest__suggestions-container--open {
		opacity: 1;
		box-shadow: rgb(128 128 128 / 30%) 0 16px 15px 0;
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		border: none;
		position: fixed;
		z-index: 3;
		background: white;
		overflow-y: scroll;
		&::-webkit-scrollbar {
			display: none;
		}
		-ms-overflow-style: none;
		scrollbar-width: none;
		padding-bottom: ${theme.paddings.XSmall};
	}
`
const VideoWrapper = styled.div`
	overflow: hidden;
	position: relative;
	height: 0;
	${theme.breakpoints.tablet.upToAndIncluding} {
		padding-bottom: 129%;
	}
	${theme.breakpoints.tablet.overAndExcluding} {
		padding-bottom: 40%;
	}
	> div:first-child {
		overflow: hidden;
		position: absolute;
		height: 0;
		width: 100%;
		${theme.breakpoints.tablet.upToAndIncluding} {
			padding-bottom: 129%;
			background-image: url('${getCloudinaryImageUrl(
				'abstract_painting_mobile_placeholder',
				200,
				'ORIGINAL_RATIO',
				',e_blur',
			)}');
		}
		${theme.breakpoints.tablet.overAndExcluding} {
			padding-bottom: 40%;
			background-image: url('${getCloudinaryImageUrl(
				'abstract_painting_desktop_placeholder',
				400,
				'ORIGINAL_RATIO',
				',e_blur',
			)}');
		}
		background-size: cover;
		filter: blur(30px);
		transform: scale(1.3); // Scaling, otherwise the edges of the blur get weird
		> video {
			opacity: ${({ loaded }) => (loaded ? 1 : 0)};
			transition: opacity 0.4s;
			position: absolute;
			width: 100%;
		}
	}
	> div:last-child {
		background: linear-gradient(0deg, rgb(255 255 255) 0%, 30%, rgb(255 255 255 / 0%) 100%);
		position: absolute;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		> div {
			:first-child {
				margin-bottom: ${theme.margins.small};
			}
			> div:first-child {
				color: white;
				${theme.breakpoints.tablet.overAndExcluding} {
					h1 {
						font-size: 50px;
						line-height: 60px;
					}
				}
			}
		}
	}
`

const MarketplacesPage = ({ t, footerLinks, routes, ssrCourseCardData }) => {
	const { title, description, image, frontendComponents } = getRootDomainStartPageData()
	const [videoLoaded, setVideoLoaded] = useState(false)
	const [searchResultsElement, setSearchResultsElement] = useState(null)
	const [fetchedCourseIds, setFetchedCourseIds] = useState(
		ssrCourseCardData?.courseCards ? ssrCourseCardData?.courseCards?.slice(0, 4).map(courseCard => courseCard.id) : [], // Slice first 4 if the ssr is loaded from e.g. all courses page, otherwise we will exclude too much
	)
	useEffect(() => {
		const video = document.getElementById('HEADER_VIDEO')
		if (video) {
			video.addEventListener(
				'loadeddata',
				() => {
					setVideoLoaded(true)
				},
				false,
			)
			video.load() // Event listener loadeddata isn't triggered unless we do load(), even though we have autoPlay
		}
	}, [])
	// Update the fetchedCourseIds everytime courses gets fetched on this page so we don't display the same ones multiple times
	const onFetch = moreFetchedCourseIds => setFetchedCourseIds([...fetchedCourseIds, ...moreFetchedCourseIds])
	const ref = useCallback(node => setSearchResultsElement(node), [])
	const getVideoUrl = type => getCloudinaryVideoUrl(`abstract_painting_${type}`)
	return (
		<>
			<HelmetHead title={title} description={description} image={image} topLevelHelmet />
			<VideoWrapper loaded={videoLoaded}>
				<div>
					<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
						<video id="HEADER_VIDEO" preload="auto" muted loop autoPlay playsInline width="100%" height="100%">
							<source src={getVideoUrl('mobile')} />
						</video>
					</Breakpoint>
					<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
						<video id="HEADER_VIDEO" preload="auto" muted loop autoPlay playsInline width="100%" height="100%">
							<source src={getVideoUrl('desktop')} />
						</video>
					</Breakpoint>
				</div>
				<div>
					<PageSidePaddingWrapper>
						<CenteredSection>
							<h1>{title}</h1>
							<p>{description}</p>
							<BigSearchBar t={t} searchResultsElement={searchResultsElement} />
						</CenteredSection>
					</PageSidePaddingWrapper>
					<MarketplaceSlider marketplaces={footerLinks.marketplaces} />
				</div>
			</VideoWrapper>
			<SearchResults ref={ref} />
			<PageSidePaddingWrapper>
				<CourseCardsWrapper
					type="workshops"
					optimizeRows
					quantity={4}
					title={t('copy:marketplacePage.courseCardsTitle')}
					ssrCourseCardData={ssrCourseCardData}
					onFetch={onFetch}
				/>
				<CenteredPageSection>
					<Link to={routes.course.index.path}>
						<Button color="OUTLINED_BLACK">{t('common:buttons.wholeOffering')}</Button>
					</Link>
				</CenteredPageSection>
			</PageSidePaddingWrapper>
			{frontendComponents.map((frontendComponent, key) => (
				<SaFrontendComponent
					key={key}
					frontendComponent={frontendComponent}
					fetchedCourseIds={fetchedCourseIds}
					onFetch={onFetch}
				/>
			))}
		</>
	)
}

const MarketplacesWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: ${theme.margins.small};
	align-items: center;
	position: relative;
	margin-top: ${theme.margins.large};
	max-width: 600px;
`

const MarketplaceSlider = ({ marketplaces }) => {
	return (
		<>
			<Breakpoint breakpoint={theme.breakpoints.tablet.upToAndIncluding}>
				<ReusableSlider desktopSlidesToShow={5} settings={{ variableWidth: true }}>
					{marketplaces.map((marketplace, key) => (
						<ConditionalLink to={isRootDomain() ? marketplace.path : marketplace.url} key={key}>
							<Button buttonType="ROUND_THIN" color="WHITE">
								{marketplace.title}
							</Button>
						</ConditionalLink>
					))}
				</ReusableSlider>
			</Breakpoint>
			<Breakpoint breakpoint={theme.breakpoints.tablet.overAndExcluding}>
				<MarketplacesWrapper>
					{marketplaces.map((marketplace, key) => (
						<ConditionalLink to={isRootDomain() ? marketplace.path : marketplace.url} key={key}>
							<Button buttonType="ROUND_THIN" color="WHITE">
								{marketplace.title}
							</Button>
						</ConditionalLink>
					))}
				</MarketplacesWrapper>
			</Breakpoint>
		</>
	)
}

export default MarketplacesPage
