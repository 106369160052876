import React, { useEffect } from 'react'
import {
	initializeSentry,
	isServerRendering,
	isSessionStorageAvailable,
	setAdminDestination,
	setAffiliate,
	setApiKeyStore,
	setIsHostSignupDomain,
	setIsIos,
	setIsRootDomain,
	setIsWebview,
	setManifestLoaded,
	setRootDomainStartPageData,
} from './utils/applicationHelper'
import fetch from 'cross-fetch'
import ScreenSizeProvider from './contexts/screen_size/ScreenSizeProvider'
import { cacheExchange, Client, fetchExchange, Provider } from 'urql'
import initializeI18n from './components/reusable_components/middleware/NewExportedI18nextProvider'
import UserProvider from './contexts/user/UserProvider'
import GroupInquiryProvider from './contexts/group_inquiry/GroupInquiryProvider'
import { setRoutes } from './hooks/useRoutes'
import { setLocation } from './hooks/useLocation'
import getCsrfToken, { setCsrfToken } from './hooks/getCsrfToken'
import { setWhiteLabel } from './hooks/getWhiteLabel'
// import { setHelpScoutAppIds } from './utils/helpScoutHelper'

//install Graphcache?

initializeSentry()

const App = props => {
	const {
		locale,
		user,
		routes,
		children,
		railsContext,
		whiteLabelIcon,
		helpScoutAppIds,
		googleMapsSdkApiKey,
		adminDestination,
		pwaSsr,
		stripePublishableKey,
		urqlSsr,
		manifestLoaded,
		marketplace,
		affiliate,
		isRootDomain,
		isHostSignupDomain,
		isWebview,
		isIos,
	} = props
	setRoutes(routes)
	setLocation(railsContext)
	setWhiteLabel({ active: !!whiteLabelIcon, icon: whiteLabelIcon })
	setCsrfToken()
	setManifestLoaded(manifestLoaded)
	setRootDomainStartPageData(marketplace)
	setIsRootDomain(isRootDomain)
	setIsHostSignupDomain(isHostSignupDomain)
	setAffiliate(affiliate)
	setIsWebview(isWebview)
	setIsIos(isIos)
	// setHelpScoutAppIds(helpScoutAppIds)
	setApiKeyStore({ GOOGLE_MAPS_SDK: googleMapsSdkApiKey, STRIPE_PUBLISHABLE_KEY: stripePublishableKey })
	const csrfToken = getCsrfToken()
	initializeI18n(locale)
	// Our Cookie consent right now isn't GDPR compatible, so there is not point in showing it. Also, Chrome will soon not allow third party cookies anyways, and iPhone will stop showing "distracting" elements such as cookie popups
	// initializeCookieConsent(locale)
	let urqlUrl = `${railsContext.origin}/${locale}/graphql`
	if (isServerRendering()) urqlUrl = urqlUrl.replace('https://', 'http://')
	const fetchOptions = {
		headers: { 'X-CSRF-Token': csrfToken, affiliate, isPwa: pwaSsr },
	}
	if (isServerRendering()) fetchOptions.headers.Cookie = `_ejbla_session=${props.ssrOnlyProps.sessionCookie}`
	if (!isServerRendering()) fetchOptions.credentials = 'same-origin'
	const client = new Client({
		url: urqlUrl,
		fetchOptions: () => {
			return fetchOptions
		},
		fetch: fetch,
		suspense: isServerRendering(),
		exchanges: [
			cacheExchange,
			urqlSsr, // Add `ssr` in front of the `fetchExchange`
			fetchExchange,
		],
	})
	if (!isServerRendering() && !window.filterCache) {
		window.filterCache = {} // Initialize the filter cache
	}
	useEffect(() => {
		if (isSessionStorageAvailable() && pwaSsr) sessionStorage.setItem('pwaInstalled', 'TRUE')

		// Clean up any old params
		// OBS: IF CHANGING THIS ALSO CHANGE PARAMS IN unnecessary_params_redirects
		const url = new URL(location.href)
		if (url.searchParams.get('modal')) {
			url.searchParams.delete('modal')
		}
		if (url.searchParams.get('cs')) {
			url.searchParams.delete('cs')
		}
		window.history.replaceState({}, document.title, url.href)
	}, [])
	setAdminDestination(adminDestination)
	return (
		<Provider value={client}>
			<ScreenSizeProvider railsContext={railsContext}>
				<UserProvider
					user={user}
					adminDestination={adminDestination}
					pwaSsr={pwaSsr}
					rootPath={routes.static.rootDomainStartPage[isRootDomain ? 'path' : 'url']}
				>
					<GroupInquiryProvider>{children}</GroupInquiryProvider>
				</UserProvider>
			</ScreenSizeProvider>
		</Provider>
	)
}

export default App
